<template>
  <van-row class="selectCity-cont">
    <van-sticky>
      <van-search style="max-height:54px;min-height:54px" v-model="searchValue" show-action placeholder="请输入搜索关键词"
        @input="onChange" @cancel="onCancel" />
    </van-sticky>

    <!-- 平铺的城市列表 长列表优化 -->
    <template v-if="searchValue.length===0">
      <v-virtual-scroll bench="4" ref="virtual" :items="virtualShowList" :item-height="44" height="calc(100vh - 56px)">
        <template v-slot="{ item }">
          <!-- 快捷方式 -->
          <van-row v-if="!Object.prototype.hasOwnProperty.call(item, 'name')">
            <van-col span="8" v-for="(data,i) in item.value" :key="i">
              <van-button style="width:100%" type="default" @click="chooseCity(data)">{{data.name}}</van-button>
            </van-col>
          </van-row>
          <!-- 标题 -->
          <van-cell style="background:#f5f5f5" v-else-if="Object.prototype.hasOwnProperty.call(item, 'islabel')" :title="item.name" />
          <!-- 条目 -->
          <van-cell v-else-if="!Object.prototype.hasOwnProperty.call(item, 'islabel')" :title="item.name" @click="chooseCity(item)" />
        </template>
      </v-virtual-scroll>
      <nav :class="'transition select-nav '+(hide=='hide'?'':'hide')">
        <div v-for="(navItem,i) in navList" :key="i" @click="jump(navItem)" :class="highlight==i?'blue':''">
          {{navItem.name}}</div>
      </nav>
    </template>
    <!-- 搜索结果列表 -->
    <template v-if="searchValue.length!==0">
      <van-cell v-for="(value,index) in onChange_stationList" :key="index" style="text-align: start" :title="value.name"
        @click="chooseStation(value)" />
    </template>
  </van-row>
</template>

<script>
import { fetchCityList } from "@/services/utils";
import { VVirtualScroll } from "vuetify/lib";

export default {
  name: "SelectCity",
  mounted() {
    this.updataList();
  },
  components: { VVirtualScroll },
  computed: {
    stationList: function() {
      const type = this.$route.query.function.split("/")[0];
      let list = fetchCityList(type, this.$route.query.allCity);
      return list;
    },
    // 高亮位置
    highlight: function() {
      let index = -1;
      for (let i = 0; i < this.navList.length; i++) {
        const element = this.navList[i];
        let offset = this.scrollTop / 44;
        if (element.max - 3 > offset && element.position - 3 <= offset) {
          index = i;
          break;
        }
      }
      return index;
    }
  },
  data() {
    return {
      virtualShowList: [], // 虚拟列表数据，有10个左右的空条目用来露出快捷方式
      cleanCol: 0, // 空数行数量
      navList: [], // 导航列表
      hide: "", // 隐藏
      scrollTop: 0, // 滚动位置
      onChange_stationList: {},
      searchValue: "",
      showlist: {},
      loading: false,
      finished: false,
      index: 0
    };
  },
  methods: {
    // 设置虚拟列表
    updataList() {
      let list = [];
      let hotList = [];
      for (let key in this.stationList) {
        if (key != "hot") {
          list.push({
            id: key,
            name: key,
            islabel: true,
            show: this.stationList[key].length > 0
          });
          list.push(...this.stationList[key]);
        } else {
          for (var i = 0; i < this.stationList[key].length; i += 3) {
            hotList.push({
              id: "hot" + i,
              value: this.stationList[key].slice(i, i + 3)
            });
          }
        }
      }
      this.cleanCol = hotList.length;
      // 组件有bug，加个白条垫着
      list.push({ colType: "finish" });
      // 过滤下
      this.virtualShowList = [...hotList, ...list].filter(e => {
        if (e.islabel) return e.show;
        return true;
      });
      this.updataNavList();
    },
    // 生成导航列表
    updataNavList() {
      let list = [];
      let i = this.cleanCol;
      for (let key in this.stationList) {
        if (key != "hot") {
          list.push({
            name: key.toUpperCase(),
            position: i++, // 上边
            max: i + this.stationList[key].length + 1 //下边
          });
          i += this.stationList[key].length;
        }
      }
      this.navList = list;
    },
    // 右侧快捷方式点击跳转
    jump(nav) {
      document.getElementById("virtualList").scrollTop = nav.position * 44;
    },
    onCancel() {
      this.searchValue = "";
      this.$router.back();
    },
    onChange(val) {
      let temp = [];
      let stationKey = Object.keys(this.stationList);
      for (let i = 1; i < stationKey.length; i++) {
        let theme = this.stationList[stationKey[i]];
        theme.forEach(item => {
          if (item.name.indexOf(val) >= 0) {
            temp.push(item);
          }
        });
      }
      this.onChange_stationList = temp;
      temp = [];
    },
    chooseCity(element) {
      this.$store.dispatch(this.$route.query.function, element);
      this.$router.back();
    },
    chooseStation(element) {
      this.$store.dispatch(this.$route.query.function, element);
      this.$router.back();
      this.onChange_stationList = {};
      this.searchValue = "";
    }
  }
};
</script>

<style lang="less">
.selectCity-cont {
  .van-index-bar__sidebar {
    border-radius: 1rem;
  }
  .van-index-bar__index {
    font-size: 0.9rem;
    padding: 0.1rem 0.5rem 0.1rem 0.7rem;
  }
}
.virtual-list {
  position: relative;
  height: calc(100vh - 54px);
  // max-height: 530px;
  overflow: auto;
}
.hide {
  opacity: 0;
  z-index: -1;
}
.select-nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  div {
    padding: 2px 1rem;
    font-size: 0.9rem;
    text-align: center;
  }
}
#quickSelect {
  position: absolute;
  width: 100%;
  background-color: #fff;
  .quick-item {
    height: 30px;
    padding: 7px 0;
  }
}
#virtualList {
  .van-index-anchor,
  .van-cell {
    min-height: 44px;
    line-height: 44px;
    padding: 0 16px;
  }
}
</style>